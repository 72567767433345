<template>
  <b-card class="mt-4">
    <b-row>
      <b-col>
      </b-col>
    </b-row>
    <div class="py-2 text-right">
      <span class="pr-2">
        <button class="btn btn-sm btn-dark" v-b-modal.modal-add-new-menu>
          <i class="fa fa-plus"></i>
        </button>
      </span>
      <span>
        <button class="btn btn-sm btn-dark" @click="fetchMenus">
          <i class="fa fa-refresh"></i>
        </button>
      </span>
      <span class="" style="vertical-align: bottom;">
        Count <strong>{{ totalRows }}</strong>
      </span>
    </div>
    <b-table sticky-header="500px" :items="menus" :fields="fields" :busy="isLoading" :isLoading="isLoading"
      responsive="xl" show-empty hover>
      <template v-slot:table-busy>
        <div class="text-center text-dark my-2">
          <b-spinner class="align-middle" variant="dark" type="grow" label="Loading ..">
          </b-spinner>
          Loading ..
        </div>
      </template>

      <template #head()="row">
        <div class="text-nowrap">
          {{ row.label }}
        </div>
      </template>
      <template #cell(role_id)="row">
        <div class="text-nowrap">
          {{ roles[row.item.role_id] }}
          <b>#{{ row.item.role_id }}</b>
          <router-link class="btn btn-sm btn-default"
            :to="{ name: SidebarMenuUpdatePath.name, params: { id: row.item.role_id } }">
            <i class="fa fa-edit"></i>
          </router-link>
        </div>
      </template>
      <template #cell(menu)="row">
        <div>
          {{ formatGetParentMenus(row.item.menu) }}
        </div>
      </template>
    </b-table>

    <b-modal id="modal-add-new-menu" title="Create Child Menu" :ok-visible="true">
      <b-form id="modal-add-new-menu" @submit.prevent="onCreateMenu">
        <b-form-group>
          <b-form-select :options="rolesOptions" v-model="form.role_id" required></b-form-select>
        </b-form-group>
        <b-button variant="primary" size="sm" class="float-right" type="submit">
          save
        </b-button>
      </b-form>
      <template #modal-footer>
        <div class="w-100"></div>
      </template>
    </b-modal>
  </b-card>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { SidebarMenuUpdatePath } from '../../router/settings';

export default {
  data() {
    return {
      SidebarMenuUpdatePath,
      roles: {},
      rolesOptions: [],
      fields: [
        {
          key: 'role_id',
          sortable: true
        },
        { key: 'menu' },
      ],
      form: {},
    }
  },
  computed: {
    ...mapState({
      menus: state => state.menus.menus,
      isLoading: state => state.menus.isLoading,
      isError: state => state.menus.isError,
      totalRows: state => state.menus.totalRows
    })
  },
  async created() {
    const roles = await this.getAllRole();
    await this.fetchMenus();
    this.setRoleOptions(roles);
  },
  watch: {
  },
  methods: {
    ...mapActions('menus', ['fetchMenus', 'postMenusByRole']),
    ...mapActions('accessRole', ['fetchAllRole']),
    onCreateMenu() {
      const payload = {
        role_id: this.form.role_id,
        menu: {
          menu: [],
        },
      };
      this.postMenusByRole(payload);
      this.$bvModal.hide('modal-add-new-menu');
    },
    async getAllRole() {
      const roles = [...await this.fetchAllRole({})];
      roles.forEach(val => { this.roles[val.id] = val.name });
      return roles;
    },
    setRoleOptions(roles) {
      const menuCreated = this.menus?.map(menu => menu.role_id);
      this.rolesOptions = roles?.map((role) => ({
        text: role.name,
        value: role.id,
      }))
        .filter((role) => (menuCreated?.includes(role.value) == false) && role.value != 19);
    },
    formatGetParentMenus(jsonMenu = {}) {
      if (!jsonMenu) return '';
      const { menu = [] } = jsonMenu;
      const parentMenu = menu.filter(val => !val.title)
        .map(val => val.name);
      return parentMenu;
    },
  },
};
</script>